import './header.scss';
import LogoKMQ from '../../../assets/logo/KnowMeQ_logo.svg';
import LogoFleming from "../../../assets/logo/Fleming-KnowMeQ-Logo.png";
import UserProfile from "../../UI/Profile/UserProfile";
import {useLocation, useNavigate} from "react-router-dom";
import {useQueryUser} from "../../../api/admin/useQueryAdmin";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {data} = useQueryUser();

  const navigateToProfile = () => {
    if (location.pathname.includes('/')) {
      navigate(`/${location.pathname.split('/')[1]}/my-profile`);
    }
  }

  return <div className={'header'}>
    <img
      className={location.pathname.includes('flemingcollege') ? 'header-logo-fleming' : 'header-logo'}
      src={location.pathname.includes('flemingcollege') ? LogoFleming : LogoKMQ}
      alt={'kmq-logo'}
      onClick={() => navigate('/')}
    />

    <span className={'header-profile'} onClick={navigateToProfile}>
      <UserProfile
        name={{
          first_name: data?.first_name ?? 'Administrator',
          last_name: data?.last_name ?? ''
        }}
        showText
      />
    </span>
  </div>
}

export default Header;
