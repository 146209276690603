import {Document, Page, Text, StyleSheet, Image, Font} from '@react-pdf/renderer';
// @ts-ignore
import Castoro from '../../styles/fonts/Castoro-Regular.ttf';
// @ts-ignore
import Montserrat from '../../styles/fonts/Montserrat-Regular.ttf';
// @ts-ignore
import MontserratBold from '../../styles/fonts/Montserrat-Bold.ttf';
import ProgramTable from "./components/ProgramTable";
import AppendixTable from "./components/AppendixTable";
import PageNumber from "./components/PageNumber";
import moment from "moment/moment";

Font.register({
  family: 'Castoro',
  fonts: [
    {
      src: Castoro,
    },
    {
      fontWeight: 'light',
      src: Montserrat
    },
    {
      fontWeight: 'bold',
      src: MontserratBold
    }
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: "24px 24px 50px 24px",
  },
  college_logo: {
    width: '210px',
    marginBottom: '16px'
  },
  kmq_logo: {
    marginBottom: '24px',
    width: 86,
  },
  title_text: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 14,
    marginBottom: 16,
  },
  reviewed_text: {
    color: '#212122',
    fontFamily: "Castoro",
    fontSize: 10,
    marginBottom: 4,
    fontWeight: 'light',
  },
  reviewed_last_text: {
    color: '#212122',
    fontFamily: "Castoro",
    fontSize: 10,
    marginBottom: 16,
    fontWeight: 'light',
  },
  summary_text: {
    color: '#212122',
    fontFamily: "Castoro",
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 16,
  },
})

const ReportFile = ({report}: { report: any }) => {

  if (!report?.programs) {
    return null;
  }

  return (
    <Document>
      <Page style={styles.page}>
        <Image src={report.logo_url} style={styles.college_logo}/>
        <Image src={'kmq_logo.png'} style={styles.kmq_logo}/>
        <Text style={styles.title_text}>
          Course Credits for {report.applicant_name}
        </Text>
        <Text style={styles.reviewed_text}>
          {
            report?.application_reviewed_by
            ? `Reviewed by ${report?.application_reviewed_by}`
            : 'Review not finished'
          }
        </Text>
        <Text style={styles.reviewed_last_text}>
          Report generated on {`${moment().format('DD/MM/YYYY')} at ${moment().format('h:mm A')}`}
        </Text>
        <Text style={styles.summary_text}>
          Summary
        </Text>

        {
          report.programs.map((item: any) => <ProgramTable program={item}/>)
        }
        <PageNumber
          title={'Summary'}
        />
      </Page>
      {
        report.courses.map((currCourse: any, courseIndex: number) =>
          <Page style={styles.page}>
            <AppendixTable
              course={currCourse}
              index={`${courseIndex + 1}`}
            />
            <PageNumber
              title={`Appendix ${courseIndex + 1} - ${currCourse.code}`}
            />
          </Page>)
      }

    </Document>
  )
}
export default ReportFile;