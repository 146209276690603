import './sign-up.scss';
import InputKMQ from "../../../components/UI/Input/InputKMQ";
import {useEffect, useState} from "react";
import {INewUser} from "../../../constants/types/INewUser";
import PasswordRequirements from "../../../components/login/PasswordRequirements/PasswordRequirements";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {object, string, ValidationError} from "yup";
import {useCreateUser} from "../../../api/login/useCreateUser";
import {useNavigate} from "react-router-dom";
import TermsOfUse from "../../../components/Router/TextWithLink/TermsOfUse";
import PrivacyPolicy from "../../../components/Router/TextWithLink/PrivacyPolicy";
import Loading from "../../../components/UI/Loading/Loading";
import ErrorKMQ from "../../../components/UI/Error/ErrorKMQ";

const SignUp = () => {
  const navigate = useNavigate();

  const [newUser, setNewUser] = useState<INewUser>({});
  const [passwordIsChecked, setPasswordIsChecked] = useState<boolean>(false);
  const [policyIsAccepted, setPolicyIsAccepted] = useState<boolean>(false);
  const [dataIsAccepted, setDataIsAccepted] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
  const [inputError, setInputError] = useState<boolean>(false);
  const {mutate, data, isPending, error} = useCreateUser();

  const signUpSchema = object({
    email: string().min(3).required(),
    password: string().min(3).required(),
    confirmation_password: string().min(3).required(),
    first_name: string().min(1).required(),
    last_name: string().min(1).required(),
    date_of_birth: string().length(10).required(),
  });

  const handleCreateUser = async () => {
    if (newUser.password && newUser.password !== newUser.confirmation_password) {
      setConfirmPasswordError(true);
    } else {
      try {
        await signUpSchema.validate(newUser);
        mutate(newUser);

        // @ts-ignore
      } catch (e: ValidationError) {
        console.log('e', e.errors)
        setInputError(true);
      }
    }
  }

  useEffect(() => {
    if (confirmPasswordError && newUser.password && newUser.password === newUser.confirmation_password) {
      setConfirmPasswordError(false);
    }
  }, [confirmPasswordError, newUser])

  useEffect(() => {
    if (data && newUser.email) {
      localStorage.setItem('user-email', newUser.email);
      navigate('/login/verification-code');
    }
  }, [data])


  return <div className={'sign-up'}>
    <Loading loading={isPending}/>
    <div className={'sign-up-content'}>
      <div className={'sign-up-title'}>
        Sign up
      </div>

      <InputKMQ
        value={newUser.first_name ?? ''}
        onChange={(e) => setNewUser({...newUser, first_name: e.target.value})}
        type={'text'}
        placeholder={'Enter first name'}
        label={'First name'}
        error={
          (inputError && !newUser.first_name)
            ? 'Please enter your first name'
            : ''
        }
      />

      <InputKMQ
        value={newUser.last_name ?? ''}
        onChange={(e) => setNewUser({...newUser, last_name: e.target.value})}
        type={'text'}
        placeholder={'Enter last name'}
        label={'Last name'}
        error={
          (inputError && !newUser.last_name)
            ? 'Please enter your last name'
            : ''
        }
      />

      <InputKMQ
        value={newUser.date_of_birth ?? ''}
        onChange={(e) => setNewUser({...newUser, date_of_birth: e.target.value})}
        type={'date'}
        placeholder={'Enter date of birth'}
        label={'Date of birth'}
        error={
          (inputError && !newUser.date_of_birth)
            ? 'Please enter your date of birth'
            : (inputError && newUser.date_of_birth?.length !== 10)
              ? 'Invalid date of birth'
              : ''
        }
      />

      <InputKMQ
        value={newUser.email ?? ''}
        onChange={(e) => setNewUser({...newUser, email: e.target.value})}
        type={'text'}
        placeholder={'Enter email'}
        label={'Email'}
        error={
          (inputError && !newUser.email)
            ? 'Please enter your email'
            // @ts-ignore
            : error?.response?.status === 409
              ? 'This email has already been registered'
              : ''
        }
      />

      <InputKMQ
        value={newUser.password ?? ''}
        onChange={(e) => setNewUser({...newUser, password: e.target.value})}
        type={'password'}
        placeholder={'Enter password'}
        label={'Create password'}
        passwordShowIcon
      />

      <InputKMQ
        value={newUser.confirmation_password ?? ''}
        onChange={(e) => setNewUser({...newUser, confirmation_password: e.target.value})}
        type={'password'}
        placeholder={'Enter password'}
        label={'Confirm password'}
        passwordShowIcon
        error={confirmPasswordError ? 'Passwords do not match' : ''}
      />

      <PasswordRequirements
        password={newUser.password}
        setPasswordCheck={setPasswordIsChecked}
      />

      <Checkbox
        active={policyIsAccepted}
        handleClick={() => setPolicyIsAccepted(!policyIsAccepted)}
        textHtml={
          <span>
            I have read, and agree to, <TermsOfUse/> and <PrivacyPolicy/>
          </span>
        }
      />

      <Checkbox
        active={dataIsAccepted}
        handleClick={() => setDataIsAccepted(!dataIsAccepted)}
        text={'I acknowledge that by proceeding with payment to KnowMeQ Incorporated for its Credit for Prior Learning tool, that my information (resume, work and education experience) will be shared with specified postsecondary institution and its staff members for credit processing'}
      />

      <div className={'sign-up-continue'}>
        <ButtonKMQ
          onClick={handleCreateUser}
          disabled={!dataIsAccepted || !policyIsAccepted || !passwordIsChecked}>
          Create account and continue
        </ButtonKMQ>
        {
          inputError
            ? <ErrorKMQ
              error={"Some fields are incomplete or data is invalid, please fill in all required information."}/>
            : null
        }
      </div>

      <div className={'sign-up-or'}>
        <div className={'sign-up-or-line'}/>
        <span className={'sign-up-or-text'}>
          Or
        </span>
      </div>

      <div className={'sign-up-login'}>
        {'Already have an account? '}
        <span className={'sign-up-login-link'} onClick={() => navigate('/login')}>
          Log in
        </span>
      </div>
    </div>
  </div>
}

export default SignUp;