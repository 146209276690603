import {ReactNode} from "react";

import './menu.scss';
import MenuElementKMQ from "./components/MenuElement/MenuElementKMQ";
import {MenuFAQElement, MenuLogoutElement} from "../../../constants/menuOptions/MenuFooterElement";
import {useLocation, useNavigate} from "react-router-dom";

export type MenuOption = {
  name: string,
  icon: ReactNode,
  onClick: () => void,
  active: boolean
}

interface MenuProps {
  options: MenuOption[],
  onLogout: () => void,
}

const MenuKMQ = (props: MenuProps) => {
  const {
    options,
    onLogout
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  return <div className={'menu'}>
    {options.length > 0
      ? options.map((item: MenuOption, index: number) =>
        <MenuElementKMQ element={item} index={index}/>)
      : null
    }

    <div className={'menu-footer'}>
      {
        location.pathname.includes('cpl')
          ? <MenuElementKMQ
            element={{
              ...MenuFAQElement,
              active: location.pathname.includes('cpl-applicant')
                ? location.pathname === '/cpl-applicant/faq'
                : location.pathname === '/cpl-admin/faq',
              onClick: location.pathname.includes('cpl-applicant')
              ? () => navigate('/cpl-applicant/faq')
              : () => navigate('/cpl-admin/faq')
            }}
            index={-1}
          />
          : null
      }

      <MenuElementKMQ
        element={{...MenuLogoutElement, onClick: onLogout}}
        index={-1}
      />
    </div>
  </div>
}

export default MenuKMQ;