import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {backendDevUrl} from "../../constants/api/backendBaseUrl";
import {loginMfaUrl} from "../../constants/api/endpoints";

export const useNewMfaCode = () => {

  async function putData(data: { email: string }) {
    return axios.put(backendDevUrl + loginMfaUrl,
      {
        email: data.email,
      },
      {}
    )
  }

  return useMutation({
    mutationKey: ['put-new-mfa'],
    mutationFn: putData,
    retry: false
  });
}