import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {backendDevUrl} from "../../constants/api/backendBaseUrl";
import {loginMfaUrl} from "../../constants/api/endpoints";

export const useMfaLogin = () => {

  async function postData(data: { email: string, code: string }) {
    return axios.post(backendDevUrl + loginMfaUrl,
      {
        email: data.email,
        code: data.code
      },
      {}
    )
  }

  return useMutation({
    mutationKey: ['post-login-mfa'],
    mutationFn: postData,
    retry: false
  });
}