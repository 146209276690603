import {useQuery} from "@tanstack/react-query";
import {programsUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

interface GetProgramsParams {
  institution_id?: string,
  portfolio_id?: string | null,
  program_id?: string,
  searchValue?: string,
  sortingValue?: string,
  name_only?: boolean,
  current_page?: number
}

export const useQueryPrograms = (params: GetProgramsParams) => {
  const {
    institution_id,
    portfolio_id,
    program_id,
    searchValue,
    sortingValue,
    name_only,
    current_page
  } = params;

  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(programsUrl, {
      params: {
        institution_id,
        portfolio_id,
        program_id,
        search_term: searchValue,
        ordering: sortingValue,
        name_only,
        current_page
      }
    })
      .then(response => {
        if (program_id) {
          return response?.data?.programs[0] ?? response;
        } else if (name_only) {
          return response?.data?.programs ?? response;
        } else {
          return response?.data ?? response;
        }
      });
  }

  return useQuery({queryKey: ['programs'], queryFn: handleRequest});

}
