import {useNavigate} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";
import React, {useContext, useEffect, useState} from "react";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import {ISortingOption} from "../../../components/UI/SearchAndSort/SearchAndSort";
import {programsSortingOptions} from "../../../constants/ui/sortingOptions";
import {useQueryPrograms} from "../../../api/admin/useQueryPrograms";
import {testing_institution_id} from "../../../constants/api/institution_id";
import {IProgram} from "../../../constants/types/IProgram";
import PlarCard, {PlarCardType} from "../../../components/UI/Cards/Plar/PlarCard";
import Loading from "../../../components/UI/Loading/Loading";
import ComplexSearch from "../../../components/UI/ComplexSearch/ComplexSearch";
import {useMutateCourseFeedback} from "../../../api/cpl-applicant/useMutateFavourite";
import SubmitPaymentButton from "../../../components/Payment/SubmitPaymentButton";
import PortfolioChiclet from "../../../components/UI/Chiclets/PortfolioChiclet/PortfolioChiclet";
import Pagination from "../../assessments/components/EmployeeTablePagination/Pagination";

const UserPrograms = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [portfolio] = useContext(PortfolioContext);
  const [currPage, setCurrPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortingValue, setSortingValue] = useState<ISortingOption>(programsSortingOptions[2]);
  const [init, setInit] = useState<boolean>(false);

  const {data, isLoading: isLoadingPrograms} = useQueryPrograms({
    portfolio_id: portfolio.id,
    institution_id: testing_institution_id,
    searchValue,
    sortingValue: sortingValue.value,
    current_page: currPage,
  });
  const {isPending: isLoadingFavourite, mutate: postFavourite} = useMutateCourseFeedback();

  const getCardInfo = (program: IProgram) => {
    if (program.num_courses_not_reviewed === program.num_courses_recommended) {
      return {type: PlarCardType.Not_started, chicletText: 'Review not started'};
    }
    if (program.num_courses_not_reviewed === 0) {
      return {type: PlarCardType.Not_started, chicletText: 'Review complete'};
    }
    return {type: PlarCardType.Not_started, chicletText: 'Review in progress'};
  }

  const handleUpdateFavourite = (programId: string) => {
    let currProgram = data.programs.find((item: IProgram) => String(item.id) === String(programId));
    if (currProgram) {
      postFavourite({
        "portfolio_id": portfolio.id,
        "program_id": programId,
        "create_favourite": !currProgram.favourite
      });
    }
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  useEffect(() => {
    if (searchValue.length > 2 || searchValue.length === 0) {
      setCurrPage(1);
      queryClient.invalidateQueries({queryKey: ['programs']});
    }
  }, [searchValue])

  useEffect(() => {
    if (init) {
      setCurrPage(1);
      queryClient.invalidateQueries({queryKey: ['programs']});
    } else {
      setInit(true);
    }
  }, [sortingValue])

  useEffect(() => {
    window.scroll(0, 0);
    queryClient.invalidateQueries({queryKey: ['programs']});
  }, [currPage])

  if (isLoadingPrograms) {
    return <Loading loading/>
  }

  return <div>
    {
      isLoadingPrograms ? <Loading loading/> : null
    }
    <div className={`user-programs ${(isLoadingFavourite) ? 'user-programs-loading' : ''}`}>
      <div>
        <PortfolioChiclet status={portfolio.status}/>
      </div>

      <div className={'user-programs-title'}>
        My Programs
      </div>
      <div className={'user-programs-text'}>
        Here, you can explore a list of eligible programs based on the resume information you have provided. You can
        also favourite programs according to your preferences, ensuring they appear at the top for the reviewer.
      </div>

      <SubmitPaymentButton/>

      <ComplexSearch
        search={{
          value: searchValue,
          onChange: (e) => setSearchValue(e.target.value),
          placeholder: 'Search Programs'
        }}
        sorting={{
          options: programsSortingOptions,
          value: sortingValue,
          onChange: (newSorting) => setSortingValue(newSorting)
        }}
      />

      {
        data?.programs?.length
          ? data.programs.map((item: IProgram, index: number) =>
            <div key={index}>
              <PlarCard
                {...getCardInfo(item)}
                title={item.name}
                subtitle={`${item.num_courses_recommended} courses recommended`}
                onClick={() => navigate(`/cpl-applicant/my-programs/${item.id}`)}
                buttonText={'View recommended courses'}
                statusText={item.num_courses_not_reviewed === 0
                  ? [`${item.num_courses_recommended} courses approved on ${item.reviewed_at_date} by ${item.reviewer_name}`]
                  : undefined
                }
                additionalButton={{
                  buttonText: 'View details',
                  onClick: () => navigate(`/cpl-applicant/my-programs/${item.id}/details`)
                }}
                showFavourite={{
                  active: item.favourite,
                  toggleActive: () => handleUpdateFavourite(String(item.id))
                }}
              />
            </div>)
          : null
      }

      {
        Number(data.total_pages) > 1
          ? <Pagination
            total={Number(data.total_pages)}
            currentPage={currPage}
            onPageChange={setCurrPage}
          />
          : null
      }
    </div>
  </div>
}

export default UserPrograms;