import {useQuery} from "@tanstack/react-query";
import {coursesUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

interface GetCoursesParams {
  institution_id?: string,
  portfolio_id?: string | null,
  course_code?: string,
  program_id?: string,
  searchValue?: string,
  sortingValue?: string,
  hide_paid?: boolean
}

export const useQueryCourses = (params: GetCoursesParams) => {
  const {
    institution_id,
    portfolio_id,
    course_code,
    program_id,
    searchValue,
    sortingValue,
    hide_paid
  } = params;
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(coursesUrl, {
      params: {
        institution_id,
        portfolio_id,
        course_code: course_code,
        program_id,
        search_term: searchValue,
        ordering: sortingValue,
        hide_paid
      }
    })
      .then(response => response?.data?.data ?? response);
  }

  return useQuery({queryKey: ['courses'], queryFn: handleRequest})

}
