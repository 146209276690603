import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {backendDevUrl} from "../../constants/api/backendBaseUrl";
import {postRefreshTokenUrl} from "../../constants/api/endpoints";

export const useRefreshToken = () => {

  async function postData(refreshToken: string) {
    return axios.post(backendDevUrl + postRefreshTokenUrl, {
      refresh: refreshToken
    })
  }

  return useMutation({
    mutationKey: ['refresh-token'],
    mutationFn: postData,
  });
}
