import {useContext, useEffect, useState} from "react";
import {UserContext} from "../../../hooks/Context/UserContext";
import {PermissionType} from "../../../constants/types/PermissionType";
import MenuLayout from "../../Menu/MenuLayout";
import {CplApplicantMenuOptions} from "../../../constants/menuOptions/CplApplicantMenuOptions";
import {useQueryUserInfo} from "../../../api/admin/useQueryUserInfo";
import {PortfolioContext, PortfolioStatusTypes} from "../../../hooks/Context/PortfolioContext";
import Loading from "../../UI/Loading/Loading";

const CplApplicantLayout = () => {
  const [user] = useContext(UserContext);
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const {refetch} = useQueryUserInfo({});
  const [showLoading, setShowLoading] = useState<boolean>(true);

  useEffect(() => {
    if (showLoading || (!portfolio.id && user.permission_groups?.includes(PermissionType.cpl_applicant))) {
      refetch().then(response => {
        if (response.isError) {
          throw new Error('portfolio not valid');
        } else {
          setShowLoading(false);
          if (response.data) {
            setPortfolio(response.data)
          }
        }
      }).catch(() => {
        setPortfolio({status: PortfolioStatusTypes.not_created, id: 'unset'});
        setShowLoading(false);
      })
    }
  }, [user, portfolio, showLoading])

  if (!showLoading && user.permission_groups?.includes(PermissionType.cpl_applicant)) {
    return <MenuLayout menuOptions={CplApplicantMenuOptions}/>
  }

  return <Loading loading/>;
}

export default CplApplicantLayout;