import {ListBullets, User} from '@phosphor-icons/react';

export const CplApplicantMenuOptions = [
  {
    name: 'My Profile',
    icon: <User size={18} color="#212121"/>,
    link: '/cpl-applicant'
  },
  {
    name: 'My Programs',
    icon: <ListBullets size={18} color="#212121"/>,
    link: '/cpl-applicant/my-programs'
  },
]