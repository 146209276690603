import './normalization-option.scss';
import CheckIcon from "../../../../../assets/icons/UI/icons/checkmark.svg";
import CheckEmptyIcon from "../../../../../assets/icons/UI/icons/checkmark-empty.svg";
import MenuOpenIcon from "../../../../../assets/icons/UI/select/arrow-down.svg";
import MenuCloseIcon from "../../../../../assets/icons/UI/select/arrow-up.svg";
import {useState} from "react";

interface NormalizationOptionProps {
  title: string,
  description?: string,
  onChoose: () => void,
  active: boolean
}

const NormalizationOption = (props: NormalizationOptionProps) => {
  const {
    title,
    description,
    onChoose,
    active
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  return <div className={`normalization-option ${menuIsOpen ? 'normalization-option-open' : ''}`}>
    <div className={'normalization-option-title'}>
      <img
        src={active ? CheckIcon : CheckEmptyIcon}
        alt={"check-icon"}
        onClick={onChoose}
        className={'normalization-option-title-active'}
      />

      {title}

      {
        description
          ? <img
            src={menuIsOpen ? MenuCloseIcon : MenuOpenIcon}
            alt={menuIsOpen ? "close-menu" : 'open-menu'}
            onClick={() => setMenuIsOpen(!menuIsOpen)}
            className={'normalization-option-title-menu-toggle'}
          />
          : null
      }
    </div>

    {
      menuIsOpen
        ? <div className={'normalization-option-text'}>
          {description}
        </div>
        : null
    }
  </div>
}

export default NormalizationOption;