import './applicant-profile.scss';
import {useNavigate, useParams} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";
import React, {useContext, useEffect, useState} from "react";
import {ISortingOption} from "../../../../components/UI/SearchAndSort/SearchAndSort";
import {programsSortingOptionsAdmin} from "../../../../constants/ui/sortingOptions";
import {useQueryPrograms} from "../../../../api/admin/useQueryPrograms";
import {useQueryReport} from "../../../../api/admin/useQueryReport";
import {useQueryUserInfo} from "../../../../api/admin/useQueryUserInfo";
import {IProgram} from "../../../../constants/types/IProgram";
import {PlarCardType} from "../../../../components/UI/Cards/Plar/PlarCard";
import moment from "moment/moment";
import Loading from "../../../../components/UI/Loading/Loading";
import UserProfile from "../../../../components/UI/Profile/UserProfile";
import {PDFDownloadLink} from "@react-pdf/renderer";
import ReportFile from "../../../../components/Report/ReportFile";
import ButtonKMQ from "../../../../components/UI/Button/ButtonKMQ";
import Info from "../../../../components/UI/Info/Info";
import ComplexSearch from "../../../../components/UI/ComplexSearch/ComplexSearch";
import {ButtonTypes} from "../../../../constants/ui/types/ButtonTypes";
import {useAssignPortfolio} from "../../../../api/admin/useAssignPortfolio";
import Toggle from "../../../../components/UI/Toggle/Toggle";
import AlertModal from "../../../../components/UI/Modals/AlertModal/AlertModal";
import Breadcrumbs from "../../../../components/UI/Breadcrumbs/Breadcrumbs";
import {useFinishPortfolioReview} from "../../../../api/admin/useFinishPortfolioReview";
import {PortfolioContext} from "../../../../hooks/Context/PortfolioContext";
import {toast} from "react-toastify";
import ProgramAdminCard from "../../../../components/UI/Cards/Plar/ProgramAdminCard";
import Pagination from "../../../assessments/components/EmployeeTablePagination/Pagination";
import Notification from '../../../../components/UI/Notification/Notification';

const ApplicantProfile = () => {
  const {portfolio_id} = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const [currPage, setCurrPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortingValue, setSortingValue] = useState<ISortingOption>(programsSortingOptionsAdmin[4]);
  const [triggerDownload, setTriggerDownload] = useState<boolean>(false);
  const [assignModal, setAssignModal] = useState<boolean>(false);
  const [reviewModal, setReviewModal] = useState<boolean>(false);
  const [pdfUrl, setPdfUrl] = useState<string>('');

  const {data, isLoading: isLoadingPrograms} = useQueryPrograms({
    portfolio_id: portfolio_id,
    searchValue,
    sortingValue: sortingValue.value,
    current_page: currPage,
  });
  const {data: report, isLoading: isLoadingReport} = useQueryReport({
    portfolio_id: portfolio_id,
  });
  const {data: userInfo, isLoading: isLoadingUser, refetch} = useQueryUserInfo({
    portfolio_id: portfolio_id,
  });
  const {mutate: assignPortfolio, isSuccess: isAssignPortfolioSuccess} = useAssignPortfolio();
  const {mutate: reviewPortfolio} = useFinishPortfolioReview();

  const getCardInfo = (program: IProgram) => {
    switch (program.status) {
      case 'review_not_started':
        return {type: PlarCardType.Not_started, chicletText: 'Review not started'};
      case 'review_in_progress':
        return {type: PlarCardType.In_progress, chicletText: 'Review in progress'};
      case 'review_completed':
        return {type: PlarCardType.Completed, chicletText: 'Review complete'};
      case 'no_status':
        return {type: PlarCardType.Not_started, chicletText: undefined};
      default:
        return {type: PlarCardType.Not_started, chicletText: 'Review not started'};
    }
  };

  const handleAssignPortfolio = () => {
    assignPortfolio({
      portfolio_id: portfolio_id ?? '',
      assign: !userInfo?.assigned_to_me,
    });
    setAssignModal(false);
  };

  const handleFinishReview = () => {
    reviewPortfolio({portfolio_id});
    setReviewModal(false);
    toast.success('Portfolio review has been completed.');
    navigate('/cpl-admin');
  };

  useEffect(() => {
    refetch();
    if (searchValue.length > 2 || searchValue.length === 0) {
      setCurrPage(1);
      queryClient.invalidateQueries({queryKey: ['programs']});
    }
  }, [searchValue]);

  useEffect(() => {
    setCurrPage(1);
    queryClient.invalidateQueries({queryKey: ['programs']});
  }, [sortingValue]);

  useEffect(() => {
    if (isAssignPortfolioSuccess) {
      refetch();
      setPortfolio({});
    }
  }, [isAssignPortfolioSuccess]);

  useEffect(() => {
    window.scroll(0, 0);
    queryClient.invalidateQueries({queryKey: ['programs']});
  }, [currPage]);

  useEffect(() => {
    if (triggerDownload && pdfUrl) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = pdfUrl;
      a.download = `${userInfo?.portfolio_user_name.replace(' ', '-')}_${moment().format('DD-MM-YYYY')}`;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(pdfUrl);
        document.body.removeChild(a);
      }, 0);
      setTriggerDownload(false);
    }
  }, [triggerDownload, pdfUrl]);

  if (isLoadingPrograms || isLoadingReport || isLoadingUser) {
    return <Loading loading />;
  }

  return (
    <div>
      {isLoadingPrograms || triggerDownload ? <Loading loading /> : null}
      <div className="applicant-profile">
        <Breadcrumbs
          content={[
            {
              label: 'Applicants',
              link: `/cpl-admin`,
            },
            {
              label: `${userInfo.portfolio_user_name}’s profile`,
              link: `/cpl-admin/${portfolio_id}`,
            },
          ]}
        />
        <Notification>
          Please assign the applicant to yourself before starting the review process.
        </Notification>
        <UserProfile
          name={{
            first_name: userInfo?.portfolio_user_name.split(' ')[0],
            last_name: userInfo?.portfolio_user_name.split(' ')[1],
          }}
          size="large"
          showText
        />
        <div className="applicant-profile-buttons">
          <ButtonKMQ
            disabled={!userInfo.assigned_to_me || !userInfo.can_finish}
            onClick={() => setReviewModal(true)}
          >
            Finish review
          </ButtonKMQ>
          <Info
            text={
              'By clicking "Finish Review," you will close this case and will no longer be able to approve or reject any credits for this applicant. Once the review is completed, no further changes can be made to this application.'
            }
          />
          <AlertModal
            open={reviewModal}
            onClose={() => setReviewModal(false)}
            onProceed={handleFinishReview}
            title="Finish review?"
            text="Are you sure you want to finish the review of this portfolio?"
            closeText="No"
            proceedText="Yes"
          />

          <PDFDownloadLink
            document={<ReportFile report={triggerDownload ? report : {}} />}
            fileName={`${userInfo?.portfolio_user_name.replace(' ', '-')}_${moment().format('DD-MM-YYYY')}`}
          >
            {({ blob, url, loading, error }) => {
              if (url && pdfUrl !== url) {
                setPdfUrl(url);
              }
              return (
                <ButtonKMQ
                  onClick={() => setTriggerDownload(true)}
                  disabled={report.programs.length === 0}
                  type={ButtonTypes.Secondary}
                >
                  Generate report
                </ButtonKMQ>
              );
            }}
          </PDFDownloadLink>
          <Info
            text={
              'To generate a report for the applicant\'s approved course credits, click this button. The report will contain a summary of all accepted courses approved by you for the applicant you are reviewing. Courses will be divided into their corresponding programs, with an appendix of attached evidence. You can generate the report at any time, and it will include any new courses approved since the last report'
            }
          />

          <div className="applicant-profile-buttons-toggle">
            {userInfo.assigned_to && userInfo.assigned_to_me===false ? (
              <Toggle
                active={false}
                onToggle={() => {}}
                disabled
                label={`Assigned to: ${userInfo.assigned_to}`}
              />
            ) : (
              <Toggle
                active={userInfo.assigned_to_me}
                onToggle={() => setAssignModal(true)}
                label={`Assigned to: ${userInfo.assigned_to_me ? 'Me' : 'N/A'}`}
              />
            )}
            <AlertModal
              open={assignModal}
              onClose={() => setAssignModal(false)}
              onProceed={handleAssignPortfolio}
              text={
                userInfo.assigned_to_me
                  ? 'Are you sure you want to unassign this user?'
                  : 'Are you sure you want the user to be assigned to you?'
              }
              closeText="No"
              proceedText="Yes"
            />
          </div>
        </div>

        <ComplexSearch
          search={{
            value: searchValue,
            onChange: (e) => setSearchValue(e.target.value),
            placeholder: 'Search Programs',
          }}
          sorting={{
            options: programsSortingOptionsAdmin,
            value: sortingValue,
            onChange: (newSorting) => setSortingValue(newSorting),
          }}
        />

        {data?.programs?.length
          ? data.programs.map((item: IProgram, index: number) => (
              <div key={index}>
                <ProgramAdminCard
                  {...getCardInfo(item)}
                  title={item.name}
                  subtitle={`${item.num_courses_recommended} courses recommended`}
                  onClick={() => navigate(`/cpl-admin/${portfolio_id}/${item.id}`)}
                  buttonText="View recommended courses"
                  statusText={
                    item.num_courses_not_reviewed === 0
                      ? [`${item.num_courses_recommended} courses reviewed on ${item.reviewed_at_date} by ${item.reviewer_name}`]
                      : undefined
                  }
                  showFavouriteAdmin={item.favourite}
                  num_cpl_eligible_recommendations={item.num_cpl_eligible_recommendations}
                  num_paid_recommendations={item.num_paid_recommendations}
                />
              </div>
            ))
          : null}

        {Number(data.total_pages) > 1 ? (
          <Pagination total={Number(data.total_pages)} currentPage={currPage} onPageChange={setCurrPage} />
        ) : null}
      </div>
    </div>
  );
};

export default ApplicantProfile;
