import {useMutation, useQueryClient} from "@tanstack/react-query";
import { addToCartUrl } from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

export const useAddToCart = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function postData(data: {course_recommendation_id: string, select_for_payment: boolean}) {
    return axios.post(addToCartUrl, data)
  }

  return useMutation({
    mutationKey: ['add-to-cart'],
    mutationFn: postData,
    onMutate: () => {
      document.body.className = 'body-loading';
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['courses']})
        .then(() => document.body.removeAttribute('class'));
    }
  });
}
