import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {backendDevUrl} from "../../constants/api/backendBaseUrl";
import {resetPasswordUrl} from "../../constants/api/endpoints";

export const useResetPassword = () => {

  async function postData(data: { password: string, confirmation_password: string }) {
    return axios.post(backendDevUrl + resetPasswordUrl,
      {
        password: data.password,
        confirmation_password: data.confirmation_password
      },
      {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('reset-password-token')
        }
      }
    )
  }

  return useMutation({
    mutationKey: ['reset-password'],
    mutationFn: postData,
    retry: false
  });
}