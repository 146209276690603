import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {backendDevUrl} from "../../constants/api/backendBaseUrl";
import {postLoginUrl} from "../../constants/api/endpoints";

export const useLogin = () => {

  async function postData(data: { email: string, password: string }) {
    return axios.post(backendDevUrl + postLoginUrl,
      {
        email: data.email,
        password: data.password
      },
      {}
    )
  }

  return useMutation({
    mutationKey: ['post-login'],
    mutationFn: postData,
    retry: false
  });
}