import "./parse-resume.scss";
import { useContext, useEffect, useState } from "react";
import { deepClone } from "../../../lib/resume-parser/deep-clone";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import { ResumeContext } from "../../../hooks/Context/ResumeContext";
import { useNavigate } from "react-router-dom";
import { useMutateRecommendation } from "../../../api/normalization/useMutateRecommendation";
import { CheckCircle, WarningCircle } from "@phosphor-icons/react";
import CplApplicantProfile from "../../../components/Resume/CplApplicantProfile/CplApplicantProfile";
import Dropzone from "../../../components/Resume/Dropzone/Dropzone";
import Loading from "../../../components/UI/Loading/Loading";
import { PortfolioContext } from "../../../hooks/Context/PortfolioContext";
import Notification from "../../../components/UI/Notification/Notification";
import AlertModal from "../../../components/UI/Modals/AlertModal/AlertModal";
import SubmittedProfileOpacityView from "../../../components/Resume/CplApplicantProfile/components/SubmittedProfileOpacityView";
import { resumeToProfile } from "../../../lib/data-formatting/resumeToProfile";
import { useQueryUserInfo } from "../../../api/admin/useQueryUserInfo";
import { portfolioToResume } from "../../../lib/data-formatting/portfolioToResume";
import { ButtonTypes } from "../../../constants/ui/types/ButtonTypes";
import ProgressBar from "./progress-bar/ProgressBar";
import CplApplicantSubmittedProfile from "../../../components/Resume/CplApplicantSubmittedProfile/CPLApplicantSubmittedProfile";

const ParseResume = () => {
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const [parsedResume, setParsedResume] = useContext(ResumeContext);
  const [currentPage, setCurrentPage] = useState('Resume');
  const [manualEntryMode, setManualEntryMode] = useState(false);
  const [currentWorkIndex, setCurrentWorkIndex] = useState(0);
  const [currentEduIndex, setCurrentEduIndex] = useState(0);
  const [isSubPageFormValid, setIsSubPageFormValid] = useState(false); // State for subpage form validation
  const [isReviewFormValid, setIsReviewFormValid] = useState(false); // State for review form validation
  const {
    mutate: postRecommendation,
    isSuccess: isRecommendationSuccess,
    isPending: isRecommendationLoading,
    isError: isRecommendationError,
  } = useMutateRecommendation(true);
  const { refetch } = useQueryUserInfo({});
  const [welcomeModalOpen, setWelcomeModalOpen] = useState<boolean | null>(null);
  const [programsModalOpen, setProgramsModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [showClearResumeAlert, setShowClearResumeAlert] = useState(false);
  const [showFileSuccessModal, setShowFileSuccessModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isRecommendationSuccess) {
      refetch().then(response => {
        if (response.data) {
          setParsedResume(portfolioToResume(response.data));
          setPortfolio(response.data);
        }
      });
      setProgramsModalOpen(true);
    }
  }, [isRecommendationSuccess]);

  useEffect(() => {
    if (welcomeModalOpen === null && portfolio.status && (!portfolio.id || !portfolio.portfolio_email)) {
      setWelcomeModalOpen(true);
    }
    setIsSubPageFormValid(true)
  }, [portfolio, welcomeModalOpen]);

  useEffect(() => {
    const isValid = validateCurrentSection();
    setIsSubPageFormValid(isValid);
    const isValidEntireForm = validateForm()
    setIsReviewFormValid(isValidEntireForm)
  }, [parsedResume, currentPage, currentEduIndex, currentWorkIndex]);

  const validateCurrentSection = (): boolean => {
    if (currentPage === 'Work Experience') {
      const work = parsedResume.work_experience[currentWorkIndex];
      return Boolean(
        work?.job_title &&
        work.company &&
        work.start_date &&
        (work.current_job || work.end_date) &&
        work.location
      );
    } else if (currentPage === 'Education') {
      const edu = parsedResume.education[currentEduIndex];
      return Boolean(
        edu?.program &&
        edu.institution_name &&
        edu.start_date &&
        (edu.currently_enrolled || edu.end_date) &&
        edu.location
      );
    }
    return true;
  };

  const validateForm = (): boolean => {
    const isValidWork = parsedResume.work_experience.every(work => Boolean(
        work?.job_title &&
        work.company &&
        work.start_date &&
        work.job_summary &&
        (work.current_job || work.end_date) &&
        work.location
    ));
    const isValidEducation = parsedResume.education.every(edu => Boolean(
        edu?.program &&
        edu.institution_name &&
        edu.start_date &&
        (edu.currently_enrolled || edu.end_date) &&
        edu.location
    ));
    return isValidWork && isValidEducation;
};

  const handleAddWork = () => {
    const newResume = deepClone(parsedResume);
    newResume.work_experience.push({ company: "", job_title: "", job_summary: "" });
    setParsedResume(newResume);
  };

  const handleAddEducation = () => {
    const newResume = deepClone(parsedResume);
    newResume.education.push({ program: "" });
    setParsedResume(newResume);
  };

  const goToNextPage = () => {
    const pageOrder = ['Resume', 'Work Experience', 'Education', 'Review'];
    const currentIndex = pageOrder.indexOf(currentPage);
    if (currentIndex < pageOrder.length - 1) {
      const nextPage = pageOrder[currentIndex + 1];
      setCurrentPage(nextPage);

      if (nextPage === 'Review') {
        setReviewModalOpen(true);
      }
    }
  };

  const goToPreviousPage = () => {
    const pageOrder = ['Resume', 'Work Experience', 'Education', 'Review'];
    const currentIndex = pageOrder.indexOf(currentPage);
    if (currentPage === 'Work Experience' && currentIndex > 0) {
      setShowClearResumeAlert(true);
    } else if (currentIndex > 0) {
      setCurrentPage(pageOrder[currentIndex - 1]);
    }
  };

  const onProceedClearResume = () => {
    setParsedResume({ basic_info: {}, work_experience: [{ company: '', job_title: '', job_summary: '' }], education: [{ program: '' }] });
    setShowClearResumeAlert(false);
    setCurrentPage('Resume');
  };

  const goToNextWork = () => {
    if (currentWorkIndex < parsedResume.work_experience.length - 1) {
      setCurrentWorkIndex(currentWorkIndex + 1);
    } else {
      goToNextPage();
    }
  };

  const goToNextEdu = () => {
    if (currentEduIndex < parsedResume.education.length - 1) {
      setCurrentEduIndex(currentEduIndex + 1);
    } else {
      goToNextPage();
    }
  };

  const goToPreviousWork = () => {
    if (currentWorkIndex > 0) {
      setCurrentWorkIndex(currentWorkIndex - 1);
    } else {
      goToPreviousPage();
    }
  };

  const goToPreviousEdu = () => {
    if (currentEduIndex > 0) {
      setCurrentEduIndex(currentEduIndex - 1);
    } else {
      goToPreviousPage();
    }
  };

  const handleSubmit = () => {
    postRecommendation({ profile: resumeToProfile(parsedResume), portfolio_id: portfolio.id });
  };

  const handleManualFill = () => {
    setManualEntryMode(true);
    setCurrentPage('Work Experience');
  };
  
  const handleFileUploadSuccess = () => {
    setShowFileSuccessModal(true);
    setCurrentPage('Work Experience');
  };

  const renderCurrentPage = () => {
    if (portfolio.id && portfolio.portfolio_email) {
      return (
        <CplApplicantSubmittedProfile  portfolio={portfolio}/>
      );
    }

    switch (currentPage) {
      case 'Resume':
        return (
            <div>
              <div className="edit-resume-my-profile-heading">
                Choose how you'd like to proceed: manually input resume or upload it to pre-fill the form with extracted information.
                Before submitting, please take a moment to review the form for any errors, missing fields, or validation requirements.
              </div>
              <Dropzone onFileUploaded={handleFileUploadSuccess} onManualFill={handleManualFill} />
            </div>
        );
      case 'Work Experience':
        return (
            <CplApplicantProfile
                type="Work Experience"
                isRecommendationError={isRecommendationError}
                handleAddWork={handleAddWork}
                handleAddEducation={handleAddEducation}
                index={currentWorkIndex}
                total={parsedResume.work_experience.length}
                goToNext={goToNextWork}
                goToPrevious={goToPreviousWork}
                setCurrentWorkIndex={setCurrentWorkIndex}
                setCurrentEduIndex={setCurrentEduIndex}
                validateCurrentSection={validateCurrentSection}
            />
        );
      case 'Education':
        return (
            <CplApplicantProfile
                type="Education"
                isRecommendationError={isRecommendationError}
                handleAddWork={handleAddWork}
                handleAddEducation={handleAddEducation}
                index={currentEduIndex}
                total={parsedResume.education.length}
                goToNext={goToNextEdu}
                goToPrevious={goToPreviousEdu}
                setCurrentWorkIndex={setCurrentWorkIndex}
                setCurrentEduIndex={setCurrentEduIndex}
                validateCurrentSection={validateCurrentSection}
            />
        );
      case 'Review':
        return (
            <CplApplicantProfile
                type="Review"
                isRecommendationError={isRecommendationError}
                handleAddWork={handleAddWork}
                handleAddEducation={handleAddEducation}
            />
        );
      default:
        return <div>Invalid page</div>;
    }
  };

  return (
      <div>
        <Loading loading={isRecommendationLoading} />
        {!portfolio.id || !portfolio.portfolio_email ? (
            <Notification>
              Please review your information carefully before submitting. You cannot edit your submission afterwards.
            </Notification>
        ) : (
            <Notification>
              You cannot edit your submission. Please contact your school advisor for any changes.
            </Notification>
        )}
        <div className="edit-resume">
          {!(portfolio.id && portfolio.portfolio_email) && <ProgressBar currentPage={currentPage} />}
          {
            (portfolio.id && portfolio.portfolio_email && !programsModalOpen)
                ? <SubmittedProfileOpacityView/>
                : null
          }
          <div className="edit-resume-title">My Profile</div>
          <div>{renderCurrentPage()}</div>
          <div className="edit-resume-bottom-navigation">
            {currentPage !== 'Resume' && <ButtonKMQ onClick={goToPreviousPage} type={ButtonTypes.Secondary}>Back</ButtonKMQ>}
            {currentPage !== 'Review' && currentPage !== 'Resume' && <ButtonKMQ onClick={goToNextPage} disabled={!isSubPageFormValid}>Continue</ButtonKMQ>}
            {currentPage === 'Review' && <ButtonKMQ onClick={handleSubmit} disabled={!isReviewFormValid}>Submit</ButtonKMQ>}
          </div>
        </div>
        <AlertModal
            open={!!welcomeModalOpen}
            onClose={() => setWelcomeModalOpen(false)}
            title="Welcome to our Course Credits platform!"
            text="We're excited to help you take the next step in your career journey. To get started, you'll need to set up your profile. You can either manually input your resume information or upload your resume file to pre-populate the fields."
            proceedText="Let’s get started"
            onProceed={() => setWelcomeModalOpen(false)}
        />
        <AlertModal
            icon={<CheckCircle size={56} color="#212121" />}
            open={programsModalOpen}
            onClose={() => setProgramsModalOpen(false)}
            title="Application submitted!"
            text="Your application has been successfully submitted! Click the button below to check your results."
            proceedText="See My Programs"
            onProceed={() => navigate('/cpl-applicant/my-programs')}
            closeText="Close"
        />
        <AlertModal
            open={showClearResumeAlert}
            onClose={() => setShowClearResumeAlert(false)}
            text="If you go back to the previous page all the filled out information will be lost and you will have to restart the process. Are you sure you want to proceed?"
            proceedText="Yes, proceed"
            onProceed={onProceedClearResume}
            title="Warning"
            closeText="No, cancel"
            icon={<WarningCircle size={56} color="#212121" />}
        />
        <AlertModal
        open={reviewModalOpen}
        onClose={() => setReviewModalOpen(false)}
        title="Review your information"
        text="Please review your information carefully before submitting. You cannot edit your submission afterwards."
        proceedText="Continue"
        onProceed={() => setReviewModalOpen(false)}
      />
      <AlertModal
        open={showFileSuccessModal}
        onClose={() => setShowFileSuccessModal(false)}
        icon={<CheckCircle size={56} color={'#212121'}/>}
        text={"Your resume has been successfully uploaded. Please continue to fill out the information."}
        proceedText={"Close"}
        onProceed={() => setShowFileSuccessModal(false)}
          />
      </div>
  );
};

export default ParseResume;
