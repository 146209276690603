import {createContext, Dispatch} from "react";

export interface IPortfolioJob {
  title: string,
  company: string,
  description: string,
  location?: string,
  normalization_choice: string,
  normalization_choice_descriptions: string,
  current_job: boolean,
  start_date: string,
  end_date: string | null,
}

export interface IPortfolioEducation {
  program: string,
  degree_type: string,
  institution_name: string,
  institution_type: string,
  location: string,
  normalization_choice: string,
  graduated: boolean,
  start_date: string
  end_date: string | null,
}

export enum PortfolioStatusTypes {
  'payment_pending'= 'Payment pending',
  'review_pending'= 'Review pending',
  'review_in_progress'= 'Review in progress',
  'review_completed'= 'Review completed',
  'not_created'= 'Not created',
}

export interface IPortfolio {
  id?: string,
  created_at?: string,
  educations?: IPortfolioEducation[],
  jobs?: IPortfolioJob[],
  portfolio_email?: string,
  portfolio_user_name?: string,
  status?: PortfolioStatusTypes,
  assigned_to_me?: boolean,
  clientSecret?: string
}

export const PortfolioContext = createContext<[IPortfolio, Dispatch<IPortfolio>]>(
  [
    {},
    () => {
    }
  ]
);