import axios from "axios";
import {backendDevUrl} from "../../constants/api/backendBaseUrl";
import {useToken} from "../jwt/useToken";
import {useCallback} from "react";

export const useAxios = () => {
  const {token, triggerRefresh} = useToken();

  const axiosHttp = axios.create({
    baseURL: backendDevUrl,
  });

  const onFulfilled = useCallback((config: any) => {
    if (token) {
      return {
        ...config,
        headers: {
          "Authorization": `Bearer ${token}`,
          ...config.headers,
        },
      };
    } else {
      return Promise.reject(new Error('no access token'));
    }
  }, [token])

  axiosHttp.interceptors.request.use(
    (config) => onFulfilled(config),

    (error) => {
      return Promise.reject(error);
    },
  );

  axiosHttp.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error?.response?.status === 401 || error?.message === 'no access token') {
        triggerRefresh()
      }
      return Promise.reject(error);
    }
  );

  return axiosHttp;
}