import {useMutation} from "@tanstack/react-query";
import {HISTORY_SIZE} from "../../constants/plar/HistorySize";
import {useAxios} from "../../hooks/axios/useAxios";

export const useMutateRecommendation = (saveHistory: boolean) => {
  const axios = useAxios();

  async function postData(data: any) {
    return axios.post('recommendation/', {
        ...data,
        config_id: localStorage.getItem('config_id')
      }
    ).then(response => response.data)
  }

  return useMutation({
    mutationKey: ['post-recommendation'],
    mutationFn: postData,
    retry: 1,
    onSuccess: (data, variables, context) => {
      // setData(data?.data?.data);

      localStorage.setItem('recommendation', JSON.stringify(data?.data?.data));

      if (saveHistory) {
        if (localStorage.getItem('history_index')) {
          let currIndex = Number(localStorage.getItem('history_index'));
          currIndex = currIndex + 1 === HISTORY_SIZE ? 0 : currIndex + 1;

          localStorage.setItem(`history_${currIndex}`, JSON.stringify(variables.profile));
          localStorage.setItem('history_index', `${currIndex}`);
        } else {
          localStorage.setItem('history_index', '0');
          localStorage.setItem('history_0', JSON.stringify(variables.profile));
        }
      }
    }
  });
}
