import {createBrowserRouter} from "react-router-dom";

import GlobalLayout from "../components/Layout/GlobalLayout/GlobalLayout";
import Config from "../app/config/Config";
import LoginWrap from "../components/login/LogionWrap/LoginWrap";
import Login from "../app/login/Login";
import LoginVerification from "../app/login/verification-code/LoginVerification";
import {assessments} from "./assessments";
import {cplApplicant} from "./cplApplicant";
import {cplAdmin} from "./cplAdmin";
import AssessmentsLayout from "../components/Layout/PermissionsLayouts/AssessmentsLayout";
import CplAdminLayout from "../components/Layout/PermissionsLayouts/CplAdminLayout";
import CplApplicantLayout from "../components/Layout/PermissionsLayouts/CplApplicantLayout";
import HomeReroute from "../components/Router/HomeReroute";
import SignUp from "../app/login/sign-up/SignUp";
import ForgotPassword from "../app/login/forgot-password/ForgotPassword";
import CreatePassword from "../app/login/create-password/CreatePassword";
import LoginActivate from "../app/login/activate/LoginActivate";
import ResetPassword from "../app/login/reset-password/ResetPassword";
import ForgotPasswordToken from "../app/login/reset-password/[token]/ForgotPasswordToken";
import PaymentAppleConfig from "../config-files/PaymentAppleConfig";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (<GlobalLayout/>),
    children: [
      {
        path: '/',
        element: <LoginWrap/>,
        children: [
          {
            path: '/login',
            element: <Login/>,
          },
          {
            path: '/login/sign-up',
            element: <SignUp/>
          },
          {
            path: '/login/verification-code',
            element: <LoginVerification/>,
          },
          {
            path: '/login/forgot-password',
            element: <ForgotPassword/>
          },
          {
            path: '/login/create-password',
            element: <CreatePassword/>
          },
          {
            path: '/login/reset-password',
            element: <ResetPassword/>
          },
          {
            path: '/login/reset-password/:token',
            element: <ForgotPasswordToken/>
          },
          {
            path: '/login/activate/:token',
            element: <LoginActivate/>
          }
        ]
      },
      {
        path: '/',
        element: <HomeReroute/>
      },
      {
        path: '/config',
        element: <Config/>
      },
      {
        path: '/assessments',
        element: <AssessmentsLayout/>,
        children: assessments
      },
      {
        path: '/cpl-admin',
        element: <CplAdminLayout/>,
        children: cplAdmin
      },
      {
        path: '/cpl-applicant',
        element: <CplApplicantLayout/>,
        children: cplApplicant
      },
    ]
  },
  {
    path: '/.well-known/apple-developer-merchantid-domain-association',
    element: <PaymentAppleConfig/>,
  }
]);