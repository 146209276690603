import './applicant-program-layout.scss';
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import Back from "../../../../components/UI/Back/Back";
import {useQueryPrograms} from "../../../../api/admin/useQueryPrograms";
import React, {useContext} from "react";
import {PortfolioContext} from "../../../../hooks/Context/PortfolioContext";
import NavigationTabs from "../../../../components/UI/NavigationTabs/NavigationTabs";
import Loading from "../../../../components/UI/Loading/Loading";
import SubmitPaymentButton from "../../../../components/Payment/SubmitPaymentButton";
import Checkbox from "../../../../components/UI/Checkbox/Checkbox";

const ApplicantProgramLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {program_id} = useParams();
  const [portfolio] = useContext(PortfolioContext);

  const {data: currProgram, isLoading} = useQueryPrograms({
    portfolio_id: portfolio.id,
    program_id: program_id
  });

  if (isLoading) {
    return <Loading loading/>
  }

  return <div className={'applicant-program-layout'}>
    <Back
      text={'Back to My Programs'}
      onClick={() => navigate('/cpl-applicant/my-programs')}
    />
    <div className={'applicant-program-layout-title'}>
      {currProgram.name}
    </div>
    <div className={'applicant-program-layout-courses'}>
      {currProgram.num_courses_recommended} courses recommended
    </div>

    <SubmitPaymentButton/>

    <NavigationTabs tabs={[
      {
        name: 'Recommended courses',
        active: !location.pathname.includes('details'),
        onClick: () => navigate(`/cpl-applicant/my-programs/${program_id}`)
      },
      {
        name: 'Program details',
        active: location.pathname.includes('details'),
        onClick: () => navigate(`/cpl-applicant/my-programs/${program_id}/details`)
      }
    ]}/>

    <Outlet/>
  </div>
}

export default ApplicantProgramLayout;