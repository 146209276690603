import {Outlet, useNavigate, useLocation} from "react-router-dom";
import {ReactNode, useState} from "react";
import "./layout.scss";

import Header from "../Headers/UserHeader/Header";
import MenuKMQ from "../UI/Menu/MenuKMQ";
import AlertModal from "../UI/Modals/AlertModal/AlertModal";
import {SignOut} from "@phosphor-icons/react";

interface MenuOption {
  name: string;
  icon: ReactNode;
}

const MenuLayout = ({menuOptions} : {menuOptions: MenuOption[]}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [logoutModalOpen, setLogoutModalOpen] = useState<boolean>(false);

  const handleLogout = () => {
    localStorage.removeItem('refresh');
    window.location.reload();
  }

  return (
    <div className={"layout"}>
      <div className={"layout-header"}>
        <Header />
      </div>

      <table className={"layout-content"}>
        <tr>
          <td className={"layout-content-menu"}>
            <MenuKMQ
              onLogout={() => setLogoutModalOpen(true)}
              options={menuOptions.map((item: any, _: number) => {
                return {
                  ...item,
                  active: item.link === location.pathname,
                  onClick: () => navigate(item.link),
                };
              })}
            />
          </td>

          <td className={"layout-content-page"}>
            <Outlet/>
          </td>
        </tr>
      </table>

      <AlertModal
        open={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        icon={<SignOut size={56} color="#212121"/>}
        title={'Log out?'}
        text={'Are you sure you want to log out? You will be signed out of your account.'}
        closeText={'Close'}
        proceedText={'Log out'}
        onProceed={handleLogout}
      />
    </div>
  );
};

export default MenuLayout;
