export const defaultFileState = {
  name: "",
  size: 0,
  fileUrl: "",
  file: {},
};

export const defaultResume = {
  'basic_info': {},
  'work_experience': [
      {
          'company': '',
          'job_title': '',
          'job_summary': ''
      }
  ],
  'education': [
      {
          'program': ''
      }
  ]
}