import './plar-card.scss';
import PlarCardStatus from "./components/CardStatus/PlarCardStatus";
import ButtonKMQ from "../../Button/ButtonKMQ";
import StatusChiclet from "../../Chiclets/StatusChiclet/StatusChiclet";
import {ButtonTypes} from "../../../../constants/ui/types/ButtonTypes";
import HeartEmpty from "../../../../assets/icons/UI/icons/heart-empty.svg";
import HeartSelected from "../../../../assets/icons/UI/icons/heart-selected.svg";

export enum PlarCardType {
  "Accepted" = "green",
  "Completed" = "green",
  "In_progress" = "yellow",
  "Rejected" = "red",
  "Not_started" = "white",
}

export interface PlarCardProps {
  type?: PlarCardType,
  title: string,
  subtitle?: string,
  header?: string,
  match?: string
  onClick: () => void,
  buttonText: string,
  chicletText?: string,
  statusText?: string[],
  cpl_eligible?: boolean,
  additionalButton?: {
    onClick: () => void,
    buttonText: string
  },
  showFavourite?: {
    active: boolean,
    toggleActive: () => void
  },
  showFavouriteAdmin?: boolean
}

const PlarCard = (props: PlarCardProps) => {
  const {
    type = PlarCardType.Not_started,
    title,
    subtitle,
    header,
    onClick,
    buttonText,
    cpl_eligible,
    additionalButton,
    showFavourite,
    showFavouriteAdmin
  } = props;

  const renderFavouriteIcon = (clickable: boolean) => {

    return <div className={`plar-card-favourite ${clickable ? '' : 'plar-card-favourite-disabled'}`}>
      <img
        src={(showFavourite?.active || !clickable) ? HeartSelected : HeartEmpty}
        alt={'heart'}
        onClick={clickable ? showFavourite?.toggleActive : undefined}
        className={'plar-card-favourite-icon'}
      />
      <span className={'plar-card-favourite-text'}>
        Favourite
      </span>
    </div>
  }

  return <div className={`plar-card plar-card-${type}`}>
    {
      cpl_eligible
        ? <StatusChiclet text={'Credit for Prior Learning Eligible'}/>
        : null
    }
    {
      header ?
        <div className={'plar-card-header'}>
          {header}
        </div>
        : null
    }

    {
      showFavouriteAdmin
        ? renderFavouriteIcon(false)
        : null
    }

    <div className={'plar-card-title'}>
      {title}
    </div>

    {
      subtitle ?
        <div className={'plar-card-subtitle'}>
          {subtitle}
        </div>
        : null
    }

    {
      showFavourite
        ? renderFavouriteIcon(true)
        : <PlarCardStatus {...props}/>
    }

    <div className={'plar-card-footer'}>
      <ButtonKMQ onClick={onClick}>
        {buttonText}
      </ButtonKMQ>
      {
        additionalButton
          ? <ButtonKMQ onClick={additionalButton.onClick} type={ButtonTypes.Secondary}>
            {additionalButton.buttonText}
          </ButtonKMQ>
          : null
      }
    </div>
  </div>
}

export default PlarCard;