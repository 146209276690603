import {CheckSquare, Square} from '@phosphor-icons/react';
import './checkbox.scss';
import {ReactNode} from "react";

interface CheckboxProps {
  active?: boolean,
  handleClick: () => void,
  text?: string,
  textHtml?: ReactNode,
  error?: boolean,
}

const Checkbox = (props: CheckboxProps) => {
  const {
    active,
    handleClick,
    text,
    textHtml,
    error
  } = props;

  return <div className={'checkbox'}>
    <span>
      {
        active
          ? <CheckSquare
            size={28}
            color={'#212121'}
            onClick={handleClick}
          />
          : <Square
            size={28}
            color={error ? '#E81E08' : '#212121'}
            onClick={handleClick}
          />
      }
    </span>
    {
      text
        ? <span className={'checkbox-text'}>
          {text}
        </span>
        : textHtml
          ? <span
            className={'checkbox-text'}
          >
          {textHtml}
          </span>
          : null
    }
  </div>
}
export default Checkbox;