import { useMutation } from "@tanstack/react-query";
import { postResumeParserUrl } from "../../constants/api/endpoints";
import { useAxios } from "../../hooks/axios/useAxios";

export const useParseResume = () => {
  const axios = useAxios();

  const postData = async (file: any) => {
    return axios.post(postResumeParserUrl, file, {
      headers: { "Content-Type": "multipart/form-data" },
    }).then(response => response.data);
  }

  return useMutation({
    mutationKey: ['post-parse-resume'],
    mutationFn: postData,
  });
};
