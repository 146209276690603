import {useMutation} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";

export const useMutateNormalization = (setResults: (newData: any) => void) => {
  const axios = useAxios();

  async function handleRequest(data: any) {
    if (data) {
      return axios.post('normalization/', {
        "profile": {
          ...data,
        },
        config_id: localStorage.getItem('config_id')
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      }).then(response => response?.data ?? response);
    }
  }

  return useMutation({
    mutationKey: ['post-normalization'],
    mutationFn: handleRequest,
    onSuccess: (data) => {
      setResults(data?.data?.profile);
    }
  });
}
