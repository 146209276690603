import React, {ChangeEvent, createRef} from "react";

import './access_code.scss';
import LabelKMQ from "../Label/LabelKMQ";
import ErrorKMQ from "../Error/ErrorKMQ";

interface AccessCodeTypes {
  length: number,
  code: (number | undefined)[],
  onChange: (newValue: (number | undefined)[]) => void,
  handleNewCode: () => void,
  label?: string,
  error?: string,
  resendError?: string
}

const AccessCode = (props: AccessCodeTypes) => {
  const {
    length,
    code,
    onChange,
    label,
    error,
    resendError,
    handleNewCode
  } = props;

  const containerRef = createRef<HTMLSpanElement>();

  const focusInput = (index: number) => {
    let newFocus = containerRef.current?.getElementsByTagName('input')[index];
    if (newFocus) {
      newFocus.focus();
      // move cursor to the end
      // @ts-ignore
      setTimeout(() => newFocus.selectionStart = newFocus.selectionEnd = 2, 0);
    }
  }

  const handleChange = (newValue: string, index: number) => {
    if (newValue.length > 1 && index < length - 1 && code.length - 1 === index) {
      handleChange(newValue[1], index + 1);
      return;
    }

    if (newValue) {
      if (!isNaN(Number(newValue)) && newValue.length === 1) {
        if (index >= code.length) {
          onChange([...code, Number(newValue)])
        } else {
          onChange(code.map((item: number | undefined, currIndex: number) =>
            currIndex === index ? Number(newValue) : item
          ))
        }
        focusInput(code.length <= index ? (code.length + 1) : code.length);
      }
    } else {
      if (index === code.length - 1) {
        onChange(code.filter((item: number | undefined, currIndex: number) => currIndex !== index));
        focusInput(index - 1);
      } else {
        onChange(code.map((item: number | undefined, currIndex: number) =>
          currIndex !== index ? item : undefined));
      }
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, index: number) => {
    if (event.key === 'Backspace' && index > 0 && !code[index] && code[index] !== 0) {
      focusInput(index - 1);
    }
    if (event.key === 'ArrowLeft' && index > 0) {
      focusInput(index - 1);
    }
    if (event.key === 'ArrowRight' && index < code.length - 1) {
      focusInput(index + 1);
    }

  }

  return <span ref={containerRef} className={`access-code-container ${error ? 'access-code-container-error' : ''}`}>
    <LabelKMQ>{label}</LabelKMQ>
    {Array.from({length}).map((_: any, index: number) =>
      <input
        id={`access-code-container-input-${index}`}
        value={code.length > index ? code[index] : ''}
        key={index}
        onChange={
          (event: ChangeEvent<HTMLInputElement>) => handleChange(event.target.value, index)
        }
        onKeyDown={
          (event: React.KeyboardEvent<HTMLDivElement>) => handleKeyDown(event, index)
        }
      />
    )}
    <ErrorKMQ error={error}/>

    <div className={'access-code-container-resent'}>
      Did not get a code?
      <span className={'access-code-container-resent-trigger'} onClick={handleNewCode}>
        {" Request a new code"}
      </span>
    </div>
    <ErrorKMQ error={resendError}/>
  </span>
}

export default AccessCode;