import './submit-payment.scss';
import ButtonKMQ from "../../UI/Button/ButtonKMQ";
import {useMutateStripeSession} from "../../../api/payment/useMutateStripeSession";
import {useContext, useEffect, useState} from "react";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import {useNavigate} from "react-router-dom";
import Loading from "../../UI/Loading/Loading";
import AlertModal from "../../UI/Modals/AlertModal/AlertModal";

const SubmitPaymentButton = () => {
  const navigate = useNavigate();
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const {mutate: mutateStripeSession, isSuccess, isPending, isError, data} = useMutateStripeSession();

  const handleSubmit = () => {
    if (portfolio.id) {
      mutateStripeSession({
        portfolio_id: portfolio.id
      });
    }
  }

  useEffect(() => {
    if (isSuccess && data) {
      setPortfolio({...portfolio, clientSecret: data.client_secret})
      navigate(`/cpl-applicant/payment/${data.session_id}`)
    }
  }, [isSuccess, data, navigate])

  return <div className={'submit-payment-button'}>
    <Loading loading={isPending}/>
    <ButtonKMQ onClick={handleSubmit}>
      Submit for review
    </ButtonKMQ>
  </div>
}

export default SubmitPaymentButton;