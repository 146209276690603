import './dropzone.scss';
import React, {useContext, useEffect, useState} from "react";
import {isEqual} from "lodash";
import {CheckCircle, CloudArrowUp, Paperclip, WarningCircle, XCircle} from "@phosphor-icons/react";
import AlertModal from "../../UI/Modals/AlertModal/AlertModal";
import ButtonKMQ from "../../UI/Button/ButtonKMQ";
import {useParseResume} from "../../../api/normalization/useParseResume";
import {defaultFileState} from '../../../constants/resume/default';
import {defaultResume} from '../../../constants/resume/default';
import Loading from '../../UI/Loading/Loading';
import {ResumeContext} from "../../../hooks/Context/ResumeContext";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";

interface DropzoneProps {
  onFileUploaded?: () => void; // Making it optional, adjust based on your actual usage
  onManualFill?: () => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ onFileUploaded, onManualFill }) => {
  const [parsedResume, setParsedResume] = useContext(ResumeContext);
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const {mutate, isPending, isSuccess, data} = useParseResume();
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [file, setFile] = useState(defaultFileState);
  const [showFileSuccessModal, setShowFileSuccessModal] = useState(false);


  const onInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files;
    if (!files) return;

    const newFile = files[0];
    setNewFile(newFile);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const newFile = event.dataTransfer.files[0];
    if (newFile.name.endsWith(".pdf")) {
      setNewFile(newFile);
    }

  };

  const setNewFile = (newFile: File | undefined) => {
    if (!newFile) {
      console.error('No file provided');
      return;
    }

    if (file.fileUrl) {
      URL.revokeObjectURL(file.fileUrl);
    }

    const {name, size} = newFile;
    const fileUrl = URL.createObjectURL(newFile);
    setFile({name, size, fileUrl, file: newFile});
  };

  const handleClickButton = () => {
    if (!file.size) {
      document.getElementsByTagName("input")[0].click();
    } else if (file.size && isEqual(defaultResume, parsedResume)) {
      handleSubmitFunction();
    } else {
      setWarningModal(true);
    }
  };

  const handleSubmitFunction = () => {
    const formData = new FormData();
    // @ts-ignore
    formData.append("file", file.file);
    mutate(formData, {
      onSuccess: (response) => {
        setPortfolio({ id: response.portfolio_id });
        setParsedResume(response);
        // @TODO: Below state is not setting to true ?
        // setShowFileSuccessModal(true);
        if (onFileUploaded) {
          onFileUploaded();
        }
      },
          onError: (error) => {
        console.error('Error uploading file', error);
      }
    });
  }

  const removeFile = () => {
    setFile(defaultFileState);
  };

  // @TODO: might not need this use effect
  useEffect(() => {
    if (isSuccess && data) {
      setPortfolio({
        id: data.portfolio_id,
      })
      setParsedResume(data)
    }
  }, [isSuccess, data])

  return (
    <div className={"dropzone"}>
      <Loading text={"Uploading Resume"} loading={isPending} showProgress/>
      <div
        className={'dropzone-container'}
        onDragOver={(event) => {
          event.preventDefault();
        }}
        id={'dropzone-container'}
        onDrop={onDrop}
      >
        <div className={"dropzone-container-content"}>
          <div className={"dropzone-container-content-icon"}>
            <CloudArrowUp size={56} color={"#212121"}/>
          </div>
          <div className={"dropzone-container-content-title"}>
            Upload resume to fill the form
          </div>
          <div className={"dropzone-container-content-text"}>
            Please only use the following file formats: .pdf
            <br/>
            Max files size: 10MBs | Max files: 1
          </div>

          {
            file.size
              ? (
                <div>
                  <div className={"dropzone-container-content-file"}>
                  <span className={"dropzone-container-content-file-icon"}>
                    <Paperclip size={21} color={"#212121"}/>
                  </span>
                    <span className={"dropzone-container-content-file-text"}>
                    {file.name}
                  </span>
                    <span
                      className={"dropzone-container-content-file-close"}
                      onClick={removeFile}
                    >
                    <XCircle size={21} color={"#212121"}/>
                  </span>
                  </div>
                </div>)
              : null
          }

          <ButtonKMQ onClick={handleClickButton}>
            {file.size ? "Upload file" : "Browse"}
          </ButtonKMQ>

          <input
            accept={".pdf"}
            hidden
            type={"file"}
            onInput={onInputChange}
            className={"dropzone-container-input"}
          />
          <div className={"dropzone-container-content-manual-fill"}><span onClick={onManualFill} className={"dropzone-container-content-manual-fill-button"}>Click here</span> to fill in the form manually</div>

          <AlertModal
            open={warningModal}
            onClose={() => setWarningModal(false)}
            onProceed={() => {
              setWarningModal(false);
              handleSubmitFunction();
            }}
            text={
              `If you remove the uploaded resume or switch from manual entry to uploading a file, ` +
              `all pre-populated information will be lost. You will need to upload a new file or ` +
              `manually fill the resume. Are you sure you want to proceed?`
            }
            proceedText={"Yes, proceed"}
            closeText={"No, cancel"}
            title={"Warning"}
            icon={<WarningCircle size={56}/>}
          />
          <AlertModal
              open={showFileSuccessModal}
              onClose={() => setShowFileSuccessModal(false)}
              icon={<CheckCircle size={56} color={'#212121'}/>}
              text={"Your resume has been successfully uploaded. Please continue to fill out the information."}
              proceedText={"Close"}
              onProceed={() => setShowFileSuccessModal(false)}
          />


        </div>
      </div>
    </div>
  );
};

export default Dropzone;
