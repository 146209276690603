import './user-programs.scss';
import React, {useContext} from "react";
import Loading from "../../../components/UI/Loading/Loading";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import UserProgramsEmpty from "./UserProgramsEmpty";
import UserPrograms from "./UserPrograms";

const UserProgramsRoute = () => {
  const [portfolio] = useContext(PortfolioContext);

  if (!portfolio.status) {
    return <Loading loading/>
  }

  if (!portfolio.id || !portfolio.portfolio_email) {
    return <UserProgramsEmpty/>
  }

  return <UserPrograms/>
}

export default UserProgramsRoute;