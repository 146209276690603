import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {backendDevUrl} from "../../constants/api/backendBaseUrl";
import {registerUrl} from "../../constants/api/endpoints";
import {jsonToJwt} from "../../lib/jwt/jsonToJwt";
import {PermissionType} from "../../constants/types/PermissionType";

export const useCreateUser = () => {

  async function postData(data: any) {
    return axios.post(backendDevUrl + registerUrl,
      {
        ...data,
        permission_groups: [PermissionType.cpl_applicant],
        assign_institution: true
      },
      {
        headers: {
          "Authorization": "Bearer " + jsonToJwt({})
        }
      }
    )
  }

  return useMutation({
    mutationKey: ['sign-up'],
    mutationFn: postData,
    retry: false
  });
}