import './work-experience.scss';
import InputKMQ from "../../../../UI/Input/InputKMQ";
import {ChangeEvent, useEffect, useState} from "react";
import SelectKMQ from "../../../../UI/Select/SelectKMQ";
import TextArea from "../../../../UI/TextArea/TextArea";
import Checkbox from "../../../../UI/Checkbox/Checkbox";
import ResumeDates from "../ResumeDates/ResumeDates";
import {WarningCircle} from '@phosphor-icons/react';
import {countries} from "countries-list";
import {object, string} from "yup";
import NormalizationOption from "../NormalizationOption/NormalizationOption";
import AlertModal from "../../../../UI/Modals/AlertModal/AlertModal";

const locationOptions = [
  {label: 'Canada', value: 'Canada'},
  {label: 'United States', value: 'United States'},
  ...Object.values(countries).sort((a, b) => a.name.localeCompare(b.name)).map(item => {
    return {
      label: item.name,
      value: item.name,
    };
  }),
];

export interface IWorkExperience {
  company?: string,
  job_title?: string,
  location?: string,
  date?: string,
  parsedDate?: string[],
  job_summary?: string,
  descriptions?: string[],
  normalized?: string,
  current_job?: boolean,
  start_date?: string,
  end_date?: string
}

interface WorkExperienceProps {
  work: IWorkExperience,
  changeWorkExperience: (work: IWorkExperience) => void,
  normalizationOptions: { title: string, description: string }[],
  updateNormalization: () => void,
  isError?: boolean,
  removeJob: () => void
}

const WorkExperience = (props: WorkExperienceProps) => {
  const {
    work = {},
    changeWorkExperience,
    normalizationOptions,
    updateNormalization,
    isError,
  } = props;

  const {
    company,
    job_title,
    location,
    parsedDate,
    job_summary,
    current_job,
    normalized,
    start_date,
    end_date
  } = work;

  const [init, setInit] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);

  const handleChooseNormalization = (optionTitle: string) => {
    if (optionTitle === "None of the Above") {
      setShowModal(true);
      changeWorkExperience({ ...work, normalized: optionTitle });
    } else {
      changeWorkExperience({ ...work, normalized: optionTitle });
    }
  };


  const handleCurrJob = () => {
    changeWorkExperience({
      current_job: !current_job,
      end_date: current_job ? undefined : 'Present',
    });
  };

  const handleNormalization = () => {
    let workSchema = object({
      job_title: string().required(),
      job_summary: string().required(),
      start_date: string().optional().nullable(),
      end_date: string().optional().nullable(),
    });

    workSchema.validate({job_title, job_summary, start_date, end_date})
      .then(() => updateNormalization())
      .catch(() => {
        // console.log(e?.errors)
      })
  }


  useEffect(() => {
    if (job_title && job_summary) {
      if (!init) {
        setInit(true)
      } else {
        const timeOutId = setTimeout(() => handleNormalization(), 2000);
        return () => clearTimeout(timeOutId);
      }
    }
  }, [job_title, job_summary, start_date, end_date]);

  return (
    <div className={"work-experience"}>
      <InputKMQ
        value={company ?? ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          changeWorkExperience({company: e.target.value})
        }
        type={"text"}
        placeholder={"Enter your company"}
        label={"Company"}
      />

      <SelectKMQ
        options={locationOptions}
        value={location ? {label: location, value: location} : null}
        onChange={(newLocation) =>
          changeWorkExperience({location: newLocation.value})
        }
        label={"Location"}
        placeholder={"Select your location"}
      />

      <Checkbox
        active={current_job}
        handleClick={handleCurrJob}
        text={"I am currently working in this position"}
      />

      <ResumeDates
        changeResume={changeWorkExperience}
        active={current_job}
        start_date={start_date}
        end_date={end_date}
        isError={isError}
      />
      <InputKMQ
          value={job_title ?? ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
              changeWorkExperience({job_title: e.target.value})
          }
          type={"text"}
          placeholder={"Enter your job title"}
          label={"Job Title"}
      />
      <div className={"work-experience-description"}>
        <TextArea
            value={job_summary ?? ""}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                changeWorkExperience({job_summary: e.target.value})
            }
            placeholder={"Enter your job description"}
            label={"Job Description"}
        />
      </div>
      {(job_title?.length && job_summary?.length && normalizationOptions?.length > 0) ? (
          <div>
            <div
                className={`work-experience-notification ${
                    isError && !normalized ? "work-experience-notification-error" : ""
                }`}
            >
              <WarningCircle size={21} color={"#212121"}/>
              {normalized === "None of the Above" ? "Can't find what you're looking for?" +
                  " Try updating your job title and description. We can generate new titles for you to choose" +
                  " from based on the most relevant options." :
                  "Please choose the best option that matches your job title. " +
                  "Choosing an option will enable us to match you accurately with courses."
              }
            </div>
            {normalizationOptions.map((item: { title: string, description: string }, index: number) => (
                <NormalizationOption
                    title={item.title}
                    description={item.description}
                    onChoose={() => handleChooseNormalization(item.title)}
                    active={normalized === item.title}
                />
            ))}
          </div>
      ) : null}
      <AlertModal
          open={showModal}
          onClose={() => setShowModal(false)}
          onProceed={() => setShowModal(false)}
          text="Can't find what you're looking for? Try updating your job title and description. We can generate new titles for you to choose from based on the most relevant options."
          proceedText="Close"
          icon={<WarningCircle size={56} color={'#212121'}/>}
      />
    </div>
  );
};

export default WorkExperience;
