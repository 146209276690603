import './cpl-applicant-profile.scss';
import { useContext, useEffect, useState } from "react";
import { PlusCircle, ArrowCircleLeft, ArrowCircleRight, Trash, WarningCircle } from "@phosphor-icons/react";
import WorkExperience, { IWorkExperience } from "./components/WorkExperience/WorkExperience";
import DeleteButton from "../../UI/Button/DeleteButton/DeleteButton";
import AlertModal from "../../UI/Modals/AlertModal/AlertModal";
import { ResumeBasicInfo, ResumeEducation } from "../../../lib/resume-parser/redux/types";
import { deepClone } from "../../../lib/resume-parser/deep-clone";
import Education from "./components/Education/Education";
import { useMutateNormalization } from "../../../api/normalization/useQueryNormalization";
import { ResumeContext } from "../../../hooks/Context/ResumeContext";
import { PortfolioContext } from "../../../hooks/Context/PortfolioContext";
import { portfolioToResume } from "../../../lib/data-formatting/portfolioToResume";
import { resumeToProfile } from "../../../lib/data-formatting/resumeToProfile";

interface ParsingResumeComponentProps {
    handleAddWork: () => void;
    handleAddEducation: () => void;
    isRecommendationError: boolean;
    type: 'Work Experience' | 'Education' | 'Review';
    index?: number;
    total?: number;
    goToNext?: () => void;
    goToPrevious?: () => void;
    setCurrentWorkIndex?: (index: number) => void;
    setCurrentEduIndex?: (index: number) => void;
    portfolio_submitted?: boolean;
    validateCurrentSection?: any;
}

const CplApplicantProfile = ({
    handleAddWork,
    handleAddEducation,
    isRecommendationError,
    type,
    index = 0,
    total = 0,
    goToNext,
    goToPrevious,
    setCurrentWorkIndex,
    setCurrentEduIndex,
    portfolio_submitted,
    validateCurrentSection
}: ParsingResumeComponentProps) => {
    const [portfolio] = useContext(PortfolioContext);
    const [parsedResume, setParsedResume] = useContext(ResumeContext);
    const [currOptions, setCurrOptions] = useState({ jobs: [], educations: [] });
    const [deleteModal, setDeleteModal] = useState<number | null>(null);
    const [alertIncompleteModalOpen, setAlertIncompleteModalOpen] = useState(false);
    const [deleteEducationModal, setDeleteEducationModal] = useState(false);
    const [educationToDelete, setEducationToDelete] = useState<number | null>(null);
    const { mutate: postNormalization } = useMutateNormalization(setCurrOptions);

    const handleEditEducation = (education: ResumeEducation, index: number) => {
        const newResume = deepClone(parsedResume);
        newResume.education[index] = { ...newResume.education[index], ...education };
        setParsedResume(newResume);
    };

    const handleEditResume = (newWork: IWorkExperience, index: number) => {
        const newResume = deepClone(parsedResume);
        newResume.work_experience[index] = { ...newResume.work_experience[index], ...newWork };
        setParsedResume(newResume);
    };

    const handleNormalization = () => postNormalization(resumeToProfile(parsedResume));

    const renderNavigationButtons = () => (
        <div className="edit-resume-subpage-navigation">
            {index > 0 && (
                <div className={"edit-resume-subpage-navigation-item-left"} onClick={goToPrevious}>
                  <span className={"edit-resume-subpage-navigation-work-add-icon"}>
                      <ArrowCircleLeft size={32} color={"#212121"}/>
                  </span>
                    <span className={"edit-resume-subpage-navigation-work-add-text"}>
                      Back to {type} {index}
                  </span>
                </div>
            )}
            {index < total - 1 && (
                <div className={"edit-resume-subpage-navigation-item-right"} onClick={goToNext}>
                  <span className={"edit-resume-subpage-navigation-work-add-text"}>
                      Next to {type} {index + 2}
                  </span>
                    <span className={"edit-resume-subpage-navigation-work-add-icon"}>
                      <ArrowCircleRight size={32} color={"#212121"}/>
                  </span>
                </div>
            )}
        </div>
    );

    const handleEducationRemoval = () => {
        if (educationToDelete !== null) {
            const newEducation = parsedResume.education.filter((_, i) => i !== educationToDelete);
            setParsedResume({ ...parsedResume, education: newEducation });
    
            // navigate to the previous education entry if it exists
            if (educationToDelete > 0) {
                setCurrentEduIndex?.(educationToDelete - 1);
            } else if (newEducation.length > 0) {
                setCurrentEduIndex?.(0);
            }
    
            setEducationToDelete(null);
        }
        setDeleteEducationModal(false);
    };

    const handleWorkRemoval = (deleteIndex: number) => {
        const newWorkExp = parsedResume.work_experience.filter((_, i) => i !== deleteIndex);
        setParsedResume({ ...parsedResume, work_experience: newWorkExp });

        if (deleteIndex > 0) {
            setCurrentWorkIndex?.(deleteIndex - 1);
        } else if (newWorkExp.length > 0) {
            setCurrentWorkIndex?.(0);
        }

        setDeleteModal(null);
    };

    const addAndNavigateToWork = () => {
        if (validateCurrentSection()) {
            handleAddWork();
            setCurrentWorkIndex?.(parsedResume.work_experience.length);
        } else {
            setAlertIncompleteModalOpen(true);
        }
    };

    const addAndNavigateToEducation = () => {
        if (validateCurrentSection()) {
            handleAddEducation();
            setCurrentEduIndex?.(parsedResume.education.length);
        } else {
            setAlertIncompleteModalOpen(true);
        }
    };

    const handleProceedAddSubItem = () => {
        if (type === "Work Experience") {
            handleAddWork();
            setCurrentWorkIndex?.(parsedResume.work_experience.length);
        } else if (type === "Education") {
            handleAddEducation();
            setCurrentEduIndex?.(parsedResume.education.length);
        }
        setAlertIncompleteModalOpen(false);
    };

    useEffect(() => {
        if (parsedResume.resume_storage_name) handleNormalization();
    }, [parsedResume.resume_storage_name]);

    useEffect(() => {
        if (portfolio.id && portfolio.portfolio_email) setParsedResume(portfolioToResume(portfolio));
    }, [portfolio]);

    useEffect(() => {
        window.scroll(0, 0);
    }, []);

    const renderSection = () => {
        switch (type) {
            case "Work Experience":
                return (
                    <div>
                        {renderNavigationButtons()}
                        <div className="work-experience-notification">
                            <WarningCircle size={21} color="#212121" />
                            Please make sure to fill all the fields in existing work experiences completely before adding a new work experience.
                        </div>
                        <div className="edit-resume-header-delete">
                            <h3>Work Experience {index + 1}</h3>
                            <DeleteButton
                                onClick={() => parsedResume.work_experience.length > 1 && setDeleteModal(index)}
                                isDisabled={parsedResume.work_experience.length <= 1}
                            />
                        </div>
                        <div className="edit-resume-header-mandatory">*All fields are mandatory to be filled out</div>
                        <WorkExperience
                            work={parsedResume.work_experience[index]}
                            changeWorkExperience={(editedWork: IWorkExperience) => handleEditResume(editedWork, index)}
                            removeJob={() => setDeleteModal(index)}
                            // @ts-ignore
                            normalizationOptions={currOptions.jobs[index]?.normalization_choices || []}
                            updateNormalization={handleNormalization}
                            isError={isRecommendationError}
                        />
                        <div className={"edit-resume-work-add"} onClick={addAndNavigateToWork}>
                              <span className={"edit-resume-work-add-icon"}>
                                  <PlusCircle size={32} color={"#212121"}/>
                              </span>
                                    <span className={"edit-resume-work-add-text"}>
                                  Add work experience
                              </span>
                        </div>
                        <AlertModal
                            open={deleteModal !== null}
                            onClose={() => setDeleteModal(null)}
                            onProceed={() => deleteModal !== null && handleWorkRemoval(deleteModal)}
                            text="Are you sure you want to delete this work experience?"
                            proceedText="Yes"
                            closeText="No"
                            title="Delete Work Experience?"
                        />
                    </div>
                );
            case "Education":
                return (
                    <div>
                        {renderNavigationButtons()}
                        <div className="work-experience-notification">
                            <WarningCircle size={21} color="#212121" />
                            Please make sure to fill all the fields in existing education experiences completely before adding a new education experience.
                        </div>
                        <div className="edit-resume-header-delete">
                            <h3>Degree Program {index + 1}</h3>
                            <DeleteButton
                                onClick={() => {
                                    if (parsedResume.education.length > 1) {
                                        setEducationToDelete(index);
                                        setDeleteEducationModal(true);
                                    }
                                }}
                                isDisabled={parsedResume.education.length <= 1}
                            />
                        </div>
                        <div className="edit-resume-header-mandatory">*All fields are mandatory to be filled out</div>
                        <Education
                            education={parsedResume.education[index]}
                            changeEducation={(editedEducation: ResumeEducation) => handleEditEducation(editedEducation, index)}
                            // @ts-ignore
                            normalizationOptions={currOptions.educations[index]?.normalization_choices || []}
                            updateNormalization={handleNormalization}
                            isError={isRecommendationError}
                        />
                        <div className={"edit-resume-work-add"} onClick={addAndNavigateToEducation}>
                              <span className={"edit-resume-work-add-icon"}>
                                  <PlusCircle size={32} color={"#212121"}/>
                              </span>
                                    <span className={"edit-resume-work-add-text"}>
                                  Add Education
                              </span>
                        </div>
                        <AlertModal
                            open={deleteEducationModal}
                            onClose={() => setDeleteEducationModal(false)}
                            onProceed={handleEducationRemoval}
                            text="Are you sure you want to delete this education entry?"
                            proceedText="Yes"
                            closeText="No"
                            title="Delete Education Entry?"
                            icon={<Trash size={56} color={"#212121"}/>}
                        />
                    </div>
                );
            case "Review":
                return (
                    <div className='edit-resume-review'>
                        <div className="edit-resume-work-title">Work history</div>
                        {parsedResume.work_experience.map((item, index) => (
                            <div key={index}>
                                <div className="edit-resume-work-title-with-delete">
                                    <div className="edit-resume-work-subtitle">Work Experience {index + 1}</div>
                                    {!portfolio_submitted && (
                                        <DeleteButton
                                            isDisabled={parsedResume.work_experience.length <= 1}
                                            onClick={() => setDeleteModal(index)}
                                        />
                                    )}
                                </div>
                                <AlertModal
                                    open={deleteModal === index}
                                    onClose={() => setDeleteModal(null)}
                                    onProceed={() => handleWorkRemoval(index)}
                                    text={
                                        `Are you sure you want to delete ${item?.job_title ? `${item?.job_title} work experience` : 'this work experience'}?`
                                    }
                                    proceedText={"Yes"}
                                    closeText={"No"}
                                    title={"Delete Work Experience?"}
                                    icon={<Trash size={56} color={"#212121"}/>}
                                />
                                <WorkExperience
                                    work={item}
                                    changeWorkExperience={(editedWork: IWorkExperience) => handleEditResume(editedWork, index)}
                                    // @ts-ignore
                                    normalizationOptions={currOptions.jobs[index]?.normalization_choices || []}
                                    updateNormalization={handleNormalization}
                                    isError={isRecommendationError}
                                    removeJob={() => setDeleteModal(index)}
                                />
                            </div>
                        ))}
                        {!portfolio_submitted &&
                            <div className={"edit-resume-work-add"} onClick={handleAddWork}>
                                <span className={"edit-resume-work-add-icon"}>
                                    <PlusCircle size={32} color={"#212121"}/>
                                </span>
                                <span className={"edit-resume-work-add-text"}>
                                  Add work experience
                                </span>
                            </div>
                        }
                        <div className="edit-resume-education-title">Education history</div>
                        {parsedResume.education.map((item, index) => (
                            <div key={index}>
                                <div className="edit-resume-education-title-with-delete">
                                    <div className="edit-resume-education-subtitle">Degree Program {index + 1}</div>
                                    {!portfolio_submitted && (
                                        <DeleteButton
                                            isDisabled={parsedResume.education.length <= 1}
                                            onClick={() => {
                                                if (parsedResume.education.length > 1) {
                                                    setEducationToDelete(index);
                                                    setDeleteEducationModal(true);
                                                }
                                            }}
                                        />
                                    )}
                                </div>
                                <AlertModal
                                    open={deleteEducationModal}
                                    onClose={() => setDeleteEducationModal(false)}
                                    onProceed={handleEducationRemoval}
                                    text={"Are you sure you want to delete this degree program?"}
                                    proceedText={"Yes"}
                                    closeText={"No"}
                                    title={"Delete Degree Program?"}
                                    icon={<Trash size={56} color={"#212121"}/>}
                                />
                                <Education
                                    education={item}
                                    changeEducation={(editedEducation: ResumeEducation) => handleEditEducation(editedEducation, index)}
                                    // @ts-ignore
                                    normalizationOptions={currOptions.educations[index]?.normalization_choices || []}
                                    updateNormalization={handleNormalization}
                                    isError={isRecommendationError}
                                />
                            </div>
                        ))}
                        {!portfolio_submitted &&
                            <div className={"edit-resume-work-add"} onClick={handleAddEducation}>
                                <span className={"edit-resume-work-add-icon"}>
                                    <PlusCircle size={32} color={"#212121"}/>
                                </span>
                                <span className={"edit-resume-work-add-text"}>
                                  Add Education
                                </span>
                            </div>
                        }
                    </div>
                );
            default:
                return <div>Invalid section type</div>;
        }
    };

    return (
        <div>
            {renderSection()}
            <AlertModal
                open={alertIncompleteModalOpen}
                onClose={() => setAlertIncompleteModalOpen(false)}
                text="Please fill out all areas in the form before adding a new work experience."
                proceedText="Yes, proceed"
                onProceed={handleProceedAddSubItem}
                title="Incomplete Form"
                closeText="No, cancel"
                icon={<WarningCircle size={56} color="#212121" />}
            />
        </div>
    );
};

export default CplApplicantProfile;
