import {useQuery} from "@tanstack/react-query";
import {faqUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

export const useQueryFAQ = () => {
  const axios = useAxios();

  const getFAQ = () => {
    return axios.get(faqUrl).then(data => data.data.faq_questions)
  }

  return useQuery({
    queryKey: ['faq'],
    queryFn: getFAQ,
  })
}