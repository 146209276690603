import React, {useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {UserContext} from "../../../hooks/Context/UserContext";
import Loading from "../../UI/Loading/Loading";
import {useToken} from "../../../hooks/jwt/useToken";
import {useQueryUser} from "../../../api/admin/useQueryAdmin";
import {PermissionType} from "../../../constants/types/PermissionType";

const AuthLayout = ({children}: { children: React.ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);

  const {token, isLoading} = useToken();
  const {data, refetch} = useQueryUser();

  useEffect(() => {
    if (!token && !isLoading && !location.pathname.includes('/login')) {
      navigate('/login');
    } else if (token && !isLoading && !user?.permission_groups) {
      refetch();
    }
  }, [token, isLoading])

  useEffect(() => {
    if (data) {
      setUser(data)
    }
  }, [data])

  useEffect(() => {
    if (location.pathname === '/' && token && !isLoading && user?.permission_groups) {
      if (user.permission_groups?.includes(PermissionType.assessments_user)) {
        navigate('/assessments/dashboard');
      } else if (user.permission_groups?.includes(PermissionType.cpl_admin)) {
        navigate('/cpl-admin');
      } else if (user.permission_groups?.includes(PermissionType.cpl_applicant)) {
        navigate('/cpl-applicant');
      } else if (user.permission_groups?.includes(PermissionType.assessments_admin)) {
        navigate('/assessments/employees');
      } else {
        navigate('/login');
      }
    }
  }, [location, token, isLoading, user])


  if (token || location.pathname.includes('/login') || location.pathname === '/') {
    return <div>
      {children}
    </div>
  }

  if (isLoading) {
    return <Loading loading/>
  }

  // implement 404 page
  return <div>Not found</div>
}
export default AuthLayout;