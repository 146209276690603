import {useContext, useEffect, useState} from "react";
import {useRefreshToken} from "../../api/jwt/useRefreshToken";
import {jwtToJson} from "../../lib/jwt/jwtToJson";
import {useLocation} from "react-router-dom";
import {UserContext} from "../Context/UserContext";
import {TokenContext} from "../Context/TokenContext";

export const useToken = () => {
  const [user, setUser] = useContext(UserContext);
  const [token, setToken] = useContext(TokenContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {mutate, isSuccess, data, isPending} = useRefreshToken();
  const location = useLocation();

  const handleRefresh = () => {
    if (token.refreshToken) {
      mutate(token.refreshToken);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    if (token.accessToken) {
      if (jwtToJson(token.accessToken).exp * 1000 < Date.now()) {
        if (token.refreshToken && jwtToJson(token.refreshToken).exp * 1000 > Date.now()) {
          mutate(token.refreshToken);
        } else {
          setToken({accessToken: '', refreshToken: ''})
          localStorage.removeItem('refresh');
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      let refresh_code = localStorage.getItem('refresh');
      if (refresh_code && jwtToJson(refresh_code).exp * 1000 > Date.now()) {
        if (!isPending) {
          mutate(refresh_code);
        }
      } else {
        localStorage.removeItem('refresh');
        setIsLoading(false);
      }
    }
  }, [location])

  useEffect(() => {
    if (data?.data && isSuccess) {
      let parsedToken = jwtToJson(data?.data.refresh);
      setUser({
        ...user,
        user_id: parsedToken.user_id
      });

      setToken({
        accessToken: data?.data.access,
        refreshToken: data?.data.refresh
      })
      localStorage.setItem('refresh', data?.data.refresh)
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (token.accessToken) {
      setIsLoading(false);
    }
  }, [token.accessToken])

  return {
    token: `${token.accessToken}`,
    triggerRefresh: handleRefresh,
    isLoading: isPending || isLoading,
    setTokens: setToken,
  };
}